.cards {
  background: #fff;
  padding-bottom: 1rem;
}

.cards__item:hover {
  transform: translateY(-20px);
}

.cards__header {
  padding: 2rem 1rem 1rem 1rem;
  width: 100%;
  background-color: #fb5436;
  color: #fff;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  padding: 1rem 0;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 15px;
}

.cards__items {
  margin-bottom: 10px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 .5rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #fb5436;
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 22px;
}

.callout {
  text-align: center;
}

.cards__wrapper__mobile {
  padding-top: 1rem;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }

  .cards__wrapper__mobile {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    width: 65%;
    margin-bottom: 1rem;
    display: flex;
  }

  .cards__wrapper {
    display: none;
  }
}


@media only screen and (max-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 22px;
  }
}

.callout-title {
  padding: 1rem;
  background-color: #009da8;
  width: 100%;
  color: white;
  font-size: 24px;
}

.callout-description {
  padding: 2rem;
}

.callout {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 780px;
  width: 90%;
  margin: 0 auto 2rem auto;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 4px;
  overflow: hidden;
  text-decoration: none;
}

.callout__color {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 720px;
  width: 90%;
  margin: 0 auto;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  overflow: hidden;
  text-decoration: none;
  padding: 1rem 3rem;
  border-left: 6px solid #009da8;
}

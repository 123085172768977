.slideshow__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.work__image {
  width: 500px;
  max-height: 500px;
}

.slider__left {
  position: absolute;
  top: 60%;
  padding: 1rem;
  opacity: 75%;
}

.slider__right {
  position: absolute;
  transform: translateX(-2.7rem);
  top: 60%;
  padding: 1rem;
  opacity: 75%;
}

.about__container {
  background: #ffffff;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.about__header {
  width: 100%;
  font-size: 36px;
  color: #fff;
  background-color: #009da8;
  margin-bottom: 1rem;
}

.about__text {
  max-width: 780px;
  text-align: justify;
  padding: 2rem 1rem 1rem 1rem;
}
